var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { css } from '@emotion/react';
import { Button, Loader } from '@mantine/core';
import { forEach, isEmpty, map, reduce } from 'lodash';
import { Fragment } from 'react';
import useSWR from 'swr';
import { proxy, ref } from 'valtio';
import { apirc } from '~/configs/apirc';
import { ArgUpperCase } from '~/decorators/ArgUpperCase';
import { getSymbolFromTo } from '~/modules/monitor/getSymbolFromToUtil';
import { twAMFuturesTimeRange, twPMFuturesTimeRange, twStockTimeRange, } from '~/modules/monitor/specTimeRangeUtil';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { FrInstrumentOfSymbol } from '~/pages/heineken_template/_fr/fr_instrument/_OfSymbol';
import { _OfFuturesArray } from '~/pages/heineken_template/_fr/fr_instrument/_OfFuturesArray';
import { FrInstrumentExchange, FrInstrumentType, } from '~/pages/heineken_template/_fr/fr_instrument/_types';
import { Preset_EntirePage } from '~/pages/heineken_template/_preset/preset_EntirePage';
import { component } from '~/utils/component';
import dayAPI from '~/utils/dayAPI';
import { FrGa } from '~/modules/SDK/FrGa/FrGa';
import { isOpbsSymbol, optionTranslator } from '~/utils/optionsTranslator';
import { expectType } from '~/utils/tsd';
class FrInstrument {
    /**
     * 前端 memory cache
     *
     * - 已被程式問過的 `OfSymbol` 會存於此
     * - - 尚未程式問過的，暫且 `undefined`
     * - - 在設計上，在你問的當下瞬間就會有存好值並返給你
     * - - 在設計上，完全無值 `undefined` 的情況，只有完全找不到相關才會發生
     */
    symbols = {};
    /**
     * 開收盤時間
     *
     * - 以台灣時間為主，所有時區時差皆已經校準台灣時間
     * - - 參考 {@link FrInstrumentOfSymbol.isSessionOpen}
     *
     * @example
     *   //
     *   // 一般而言，正常使用
     *   const CN1 = fr_instrument.getSymbol('CN-1')
     *
     *   CN1.isSessionOpen === false // 當台灣時間 08:59
     *   CN1.isSessionOpen === true // 當台灣時間 09:00
     *
     *   CN1.isSessionOpen === true // 當台灣時間 16:34
     *   CN1.isSessionOpen === false // 當台灣時間 16:35
     *
     * @example
     *   //
     *   const CN1_sessions = fr_instrument.getSessions('CN-1') // [{ from, to }, { from, to }]
     */
    getSessions(symbol) {
        const type = fr_instrument.getType(symbol);
        const exchange = fr_instrument.getExchange(symbol);
        // 先查找遠端資料是否提供
        const sessionsCache = fr_instrument._cache.symbolToSessionsMapp[symbol];
        if (!isEmpty(sessionsCache)) {
            // 將遠端資料統一轉前端 dayjs 資料
            return sessionsCache.map(session => {
                const now = dayAPI();
                const todayAsPrefix = now.format('YYYY-MM-DD');
                //
                // 不在乎前面的「年月日」，因為後端也沒有給「年月日」
                // 所以我們只取時間，年月日我們讓它都是今天
                // 並且對於時差問題，皆以台灣時間為主軸來校準轉譯
                // 方便往後 UI層 實現可以「以台灣時間直接來判斷」
                const from = dayAPI(`${todayAsPrefix} ${session.open_time}:00`)
                    .tz(session.timezone, true)
                    .tz('Asia/Taipei')
                    .set('days', now.get('days')); // 為了撫平不同正負時區會使日期偏差，需要以台灣日期校準
                const to = dayAPI(`${todayAsPrefix} ${session.close_time}:00`)
                    .tz(session.timezone, true)
                    .tz('Asia/Taipei')
                    .set('days', now.get('days'));
                /**
                 * 如有跨日的話，我們再讓 to 加一天
                 *
                 * 如此當前端在使用 `now()` 來判斷 `isBetween(from, to)` 時，更佳方便
                 */
                return {
                    from,
                    to: to.isBefore(from) ? to.add(1, 'day') : to,
                };
            });
        }
        // 依前端維護
        if (RegExp(/TF-\d/i).exec(symbol)) {
            return [covertToFromToObject(twAMFuturesTimeRange(dayAPI()))];
        }
        if (RegExp(/T[EX]-\d/i).exec(symbol)) {
            return [
                covertToFromToObject(twPMFuturesTimeRange(dayAPI())),
                covertToFromToObject(twAMFuturesTimeRange(dayAPI())),
            ];
        }
        // 依條件推斷
        if (type === FrInstrumentType.台灣股票 && exchange === FrInstrumentExchange.TWSE) {
            return [covertToFromToObject(twStockTimeRange(dayAPI()))];
        }
        // 最後查找
        const fallback = getSymbolFromTo(symbol.toUpperCase(), dayAPI());
        return fallback ? [covertToFromToObject(fallback)] : [];
    }
    /**
     * 商品類型
     *
     * @example
     *   //
     *   fr_instrument.getType('CDF-1') // 'futures' // 股指 期貨
     *   fr_instrument.getType('TX-1') // 'futures' // 台指 期貨
     *   fr_instrument.getType('ES-1') // 'futures' // 標普 期貨
     *   fr_instrument.getType('JY-1') // 'futures' // 日圓 期貨
     */
    getType(symbol) {
        if (!isEmpty(fr_instrument._cache.symbolOfStockToNameMapp[symbol])) {
            return FrInstrumentType.台灣股票;
        }
        const symbolToType = fr_instrument._cache.symbolToTypeMapp[symbol];
        if (!isEmpty(symbolToType)) {
            return symbolToType;
        }
        if (isOpbsSymbol(symbol)) {
            return FrInstrumentType.台灣選擇權;
        }
        // 判斷 tvAPI 的 type
        return FrInstrumentType.未知;
    }
    /**
     * 拿「交易所名字」
     *
     * @example
     *   //
     *   fr_instrument.getExchange('2330') // 'TWSE'
     *   fr_instrument.getExchange('!@#$%^&*') // ''
     */
    getExchange(symbol) {
        // 先查找是否台灣股票交易所
        if (!isEmpty(fr_instrument._cache.symbolOfStockToNameMapp[symbol])) {
            return FrInstrumentExchange.TWSE;
        }
        if (isOpbsSymbol(symbol)) {
            return FrInstrumentExchange.TFE;
        }
        // 查找期貨交易所
        const exchangeFromTvAPI = this._cache.symbolToExchangeMapp[symbol];
        if (!isEmpty(exchangeFromTvAPI)) {
            return exchangeFromTvAPI;
        }
        return FrInstrumentType.未知;
    }
    /**
     * @example
     *   fr_instrument.getName('2330') // '台積電'
     *   fr_instrument.getName('TX118000M2') // '台指1月W1選18000Put'
     */
    getName(symbol) {
        // 先從第一優先字典裡查找
        let name = fr_instrument._cache.symbolToNameMapp[symbol];
        // 查找台灣股票代碼
        if (!name)
            name = fr_instrument._cache.symbolOfStockToNameMapp[symbol];
        // 查找台灣選擇權
        if (!name)
            name = optionTranslator(symbol);
        // 都找不到，以原投入 symbol 返回
        if (!name)
            name = symbol;
        return name;
    }
    /**
     * @example
     *   //
     *   // 飯粒
     *   const tsm = fr_instrument.getSymbol('2330')
     *
     *   tsm.name // '台積電'
     *   tsm.code // '2330'
     *   tsm.is.type.option // false
     *   tsm.is.type.stock // true
     */
    getSymbol(symbol) {
        if (!fr_instrument.symbols[symbol]) {
            fr_instrument.symbols[symbol] = proxy(new FrInstrumentOfSymbol({
                symbol,
            }));
        }
        return fr_instrument.symbols[symbol];
    }
    /** 取得每點價值 */
    getBigPointValue(symbol) {
        const value = this._cache.symbolToBigPointValue[symbol];
        if (!value) {
            /** E.g. `TX-1` */
            if (symbol.includes('TX')) {
                return 200;
            }
            /** E.g. `0050` `0051` */
            if (symbol.match(/[\d]{4}/)) {
                return 1000;
            }
        }
        return value || 0;
    }
    /**
     * @example
     *   //
     *   // 如果你要一次多代碼
     *   const symbolList = fr_instrument.getManySymbols(['2330'])
     *
     *   const tsm = symbolList[0]
     *
     *   tsm.name // '台積電'
     *   tsm.code // '2330'
     *   tsm.is.type.option // false
     *   tsm.is.type.stock // true
     */
    getManySymbols(symbols) {
        return map(symbols, code => fr_instrument.getSymbol(code));
    }
    /** 取得派生商品 的反查 */
    getUnderlying(
    /**
     * 會幫你濾掉 `TX-1` 的 「`-1`」 之類的字串
     *
     * 這是由於反查快取表 {@link fr_instrument._cache.symbolOfUnderlying} 之中，都沒有 `-1` `-2` 的字串
     *
     * @example
     *   //
     *   // 你丟
     *   const symbol = 'TX-1'
     *
     *   // 所以我會用
     *   symbol = `TX`
     *   // 來去反查快取表查詢
     *   //
     */
    symbol) {
        symbol = symbol.replace(/-.*/, '');
        const symbolString = fr_instrument._cache.symbolOfUnderlying[symbol] || null;
        if (!symbolString)
            return null;
        return fr_instrument.getSymbol(symbolString);
    }
    /** 取得派生商品 */
    getFutures(symbol) {
        const futures = new _OfFuturesArray(fr_instrument.getManySymbols(fr_instrument._cache.symbolOfStockFutures[symbol] || []));
        return futures;
    }
    /** 是否具備派生商品 */
    hasFutures(symbol) {
        const futuresSymbolString = fr_instrument.getFutures(symbol);
        if (futuresSymbolString.length)
            return true;
        return false;
    }
    /** 向後端問數據並存於 memory as cache */
    async installData() {
        //
        // 台灣股票代碼
        if (isEmpty(fr_instrument._cache.symbolOfStockToNameMapp)) {
            debugAPI.fr_instrument.log(`台灣股票代碼: 正在準備必要數據...`);
            const symbolOfStockToNameMapp = await apirc.staticJson.fetchSymbolOfStockToNameMapp();
            fr_instrument._cache.symbolOfStockToNameMapp = symbolOfStockToNameMapp;
        }
        //
        // 期貨代碼
        if (isEmpty(fr_instrument._cache.symbolToNameMapp)) {
            debugAPI.fr_instrument.log(`期貨代碼: 正在準備必要數據...`);
            const symbolToNameMapp = await apirc.staticJson.fetchSymbolToNameMapp();
            fr_instrument._cache.symbolToNameMapp = symbolToNameMapp;
        }
        //
        // 商品的一些通用資訊
        if (isEmpty(fr_instrument._cache.symbolOfStockFutures)) {
            debugAPI.fr_instrument.log(`期貨代碼: 正在準備必要數據...`);
            const commonSymbolList = await apirc.staticJson.fetchCommonSymbolList();
            fr_instrument._cache.symbolOfStockFutures = commonSymbolList.derivativeFutures;
            /** 將 restAPI 快取表的「所有子孫片段」反轉進來「前端快取表」，透過兩層 `reduce()` */
            fr_instrument._cache.symbolOfUnderlying = reduce(commonSymbolList.derivativeFutures, (cache, derivativeSymbolStringList, underlyingSymbolString) => {
                /**
                 * 子孫片段轉換
                 *
                 * 例如第一個子孫片段
                 *
                 * `TSEA: ['TX', 'MTX']`
                 *
                 * 轉換成
                 *
                 * `{ 'TX': 'TSEA', MTX: 'TSEA' }`
                 */
                const fragmentList = reduce(derivativeSymbolStringList, (fragment, derivativeSymbolString) => {
                    fragment[derivativeSymbolString] = underlyingSymbolString;
                    return fragment;
                }, {});
                return {
                    ...cache,
                    ...fragmentList,
                };
            }, {});
        }
        // TvAPIs 的交易所、類型資料
        if (isEmpty(fr_instrument._cache.symbolToExchangeMapp) ||
            isEmpty(fr_instrument._cache.symbolToTypeMapp)) {
            debugAPI.fr_instrument.log(`TvAPIs: 正在準備必要數據...`);
            //
            // TvAPIs 有的商品資訊
            const dataOfTvAPIs = await Promise.race([
                apirc.tvAPIs.fetchAll(),
                apirc.tvAPIsOfGCP.fetchAll(),
            ]);
            //
            // TvAPIs 裡頭的「type字串」參考
            forEach(dataOfTvAPIs, info => {
                fr_instrument._cache.symbolToExchangeMapp[info.symbol] = info.exchange;
                fr_instrument._cache.symbolToTypeMapp[info.symbol] = info.type.includes('_futures')
                    ? FrInstrumentType.期貨
                    : info.type;
            });
        }
        // 開收盤時間
        if (isEmpty(fr_instrument._cache.symbolToSessionsMapp)) {
            const contractInfo = await apirc.staticJson.fetchContractInfo();
            forEach(contractInfo, (info, symbol) => {
                fr_instrument._cache.symbolToBigPointValue[symbol] = info.big_point_value;
                fr_instrument._cache.symbolToSessionsMapp[symbol] = info.trading_interval.map(value => ({
                    ...value,
                    timezone: info.timezone,
                }));
            });
        }
    }
    /**
     * 用於安裝到 Page 組件
     *
     * 可使必要的遠端數據，永遠比「UI 組件」的呈現早一步準備完成
     *
     * @example
     *   //
     *   return (
     *     <Suspense fallback={`必要資訊載入中...`}>
     *       <fr_instrument.DataProvider>
     *         <TemplatePage templateProps={...{}} />
     *       </fr_instrument.DataProvider>
     *     </Suspense>
     *   )
     */
    // DataProvider = ref(
    //   component<ReactProps>(props => {
    //     const { isReady } = useRouter()
    //     const [loading, setLoading] = useState(false)
    //     const hasReady = suspend(async () => {
    //       console.info(`${debugAPI.fr_instrument.logger.namespace} 正在準備必要遠端數據...`)
    //       await fr_instrument.installData()
    //       console.info(`${debugAPI.fr_instrument.logger.namespace} 準備完成，開始 render UI`)
    //       debugAPI.fr_instrument.log(`準備完成，開始 render UI`)
    //       return true
    //     }, [])
    //     useEffect(() => {
    //       if (isReady && hasReady) setLoading(true)
    //     }, [isReady, hasReady])
    //     return (
    //       <Fragment>
    //         {loading ? <Fragment>{props.children}</Fragment> : <Preset_EntirePage />}
    //       </Fragment>
    //     )
    //   }),
    // )
    DataProvider = ref(component(props => {
        const isDataReady = useSWR('FIXED_KEY', async function fetcher() {
            console.info(`${debugAPI.fr_instrument.logger.namespace} 正在準備必要遠端數據...`);
            await fr_instrument.installData();
            console.info(`${debugAPI.fr_instrument.logger.namespace} 準備完成，開始 render UI`);
            return true;
        }, {
            keepPreviousData: true,
            refreshWhenHidden: false,
            refreshWhenOffline: false,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            errorRetryCount: 5,
            errorRetryInterval: 1000,
        });
        FrGa.useTimeSpent()
            .calc(() => isDataReady.data === true)
            ?.done(data => {
            data.ms > 0 && FrGa.event({ 首屏轉圈圈完成: { ms: data.ms } });
        });
        FrGa.useTimeSpent()
            .calc(() => !!isDataReady.error)
            ?.done(data => {
            data.ms > 0 && FrGa.event({ 首屏轉圈圈失敗: { ms: data.ms } });
        });
        return (<Fragment>
          {isDataReady.data === true && <Fragment>{props.children}</Fragment>}

          {isDataReady.error && (<Preset_EntirePage css={css `
                justify-content: center;
                align-content: center;
              `}>
              <div>必要元件載入時發生問題</div>
              <div>Error: {isDataReady.error?.message}</div>
              <Button onClick={() => {
                    FrGa.event({
                        首屏轉圈圈失敗點擊重整: {},
                    });
                    globalThis.location.reload();
                }}>
                點此重新整理
              </Button>
            </Preset_EntirePage>)}

          {isDataReady.isLoading && isDataReady.data === undefined && (<Preset_EntirePage css={css `
                justify-content: center;
                align-content: center;
              `}>
              <Loader color='red' variant='bars' size='xl'/>
            </Preset_EntirePage>)}
        </Fragment>);
    }));
    _cache = {
        /**
         * 每點價值
         *
         * - E.g. 小台每點 50 新台幣
         * - E.g. 大台每點 200 新台幣
         * - E.g. 股票每點 1000 新台幣
         *
         * @example
         *   //
         *   toBe({
         *     'TX-1': 200,
         *     '0050': 1000,
         *   })
         */
        symbolToBigPointValue: {},
        /**
         * 股票代碼
         *
         * - 只有台灣股票代碼
         * - 皆屬於交易所 `'TWSE'`
         *
         * @example
         *   //
         *   toBe({
         *     '2330': '台積電',
         *     '2886': '兆豐金',
         *     // ...更多, 略
         *   })
         */
        symbolOfStockToNameMapp: {},
        /**
         * 股期代碼
         *
         * @example
         *   //
         *   toBe({
         *     TSEA: ['TX', 'TXAM', 'TXPM', 'MTX'],
         *     '0050': ['NYF'],
         *     '0056': ['PFF'],
         *     '0061': ['NZF'],
         *     '00636': ['OJF'],
         *     '00639': ['OKF'],
         *     '1402': ['CRF'],
         *     '1440': ['EKF'],
         *     '1476': ['LWF'],
         *     '1477': ['KSF'],
         *     // ...更多, 略
         *   })
         */
        symbolOfStockFutures: {},
        /**
         * 股期代碼 {@link fr_instrument._cache.symbolOfStockFutures} 的反查
         *
         * @example
         *   //
         *   toBe({
         *     TX: 'TSEA',
         *     TXAM: 'TSEA',
         *     TXPM: 'TSEA',
         *     MTX: 'TSEA',
         *     NYF: '0050',
         *     PFF: '0056',
         *     NZF: '0061',
         *     OJF: '00636',
         *     OKF: '00639',
         *     CRF: '1402',
         *     EKF: '1440',
         *     LWF: '1476',
         *     KSF: '1477',
         *     // ...更多, 略
         *   })
         */
        symbolOfUnderlying: {},
        /**
         * 期貨代碼
         *
         * @example
         *   //
         *   toBe({
         *     'TX-1': '台指期',
         *     'TXAM-1': '台指期(日盤)',
         *     'TXPM-1': '台指期(夜盤)',
         *     'TX-2': '台指期次月',
         *     // ...更多, 略
         *   })
         */
        symbolToNameMapp: {},
        /**
         * 交易所資訊
         *
         * @example
         *   //
         *   toBe({
         *     'TX-1': 'TFE',
         *     'TXAM-1': 'TFE',
         *     'ES-1': 'CME',
         *     'TWN-1': 'SGX',
         *     // ...更多, 略
         *   })
         */
        symbolToExchangeMapp: {},
        /**
         * 商品類型
         *
         * @example
         *   //
         *   toBe({
         *     TSE17: 'index',
         *     IBM: 'os_stock',
         *     NFLX: 'os_stock',
         *     TX413900N3: 'option',
         *     'TX-1': 'futures',
         *     'CDF-1': 'futures', // 轉譯由 'stock_futures',
         *     'FSTB-1': 'futures', // 轉譯由 'os_eurex_futures',
         *     'JY-1': 'futures', // 轉譯由 'os_fx_futures',
         *     'ES-1': 'futures', // 轉譯由 'os_index_futures',
         *     'TWN-1': 'futures', // 轉譯由 'os_index_futures',
         *     // ...更多, 略
         *   })
         */
        symbolToTypeMapp: {},
        /**
         * 商品類型
         *
         * @example
         *   //
         *   toBe({
         *     'GC-1': [{ timezone: 'US/Eastern', open_time: '17:00', close_time: '16:00' }],
         *     'CN-1': [
         *       { timezone: 'Asia/Singapore', open_time: '09:00', close_time: '16:35' },
         *       { timezone: 'Asia/Singapore', open_time: '17:00', close_time: '05:15' },
         *     ],
         *     // ...更多, 略
         *   })
         */
        symbolToSessionsMapp: {},
    };
}
__decorate([
    ArgUpperCase(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Array)
], FrInstrument.prototype, "getSessions", null);
__decorate([
    ArgUpperCase(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FrInstrument.prototype, "getType", null);
__decorate([
    ArgUpperCase(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FrInstrument.prototype, "getExchange", null);
__decorate([
    ArgUpperCase(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FrInstrument.prototype, "getName", null);
__decorate([
    ArgUpperCase(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FrInstrument.prototype, "getSymbol", null);
__decorate([
    ArgUpperCase(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FrInstrument.prototype, "getBigPointValue", null);
__decorate([
    ArgUpperCase(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Object)
], FrInstrument.prototype, "getUnderlying", null);
__decorate([
    ArgUpperCase(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FrInstrument.prototype, "getFutures", null);
__decorate([
    ArgUpperCase(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], FrInstrument.prototype, "hasFutures", null);
/**
 * `FrInstrument`
 *
 * - 跟 symbol 相關的所有邏輯入口
 *
 * @example
 *   //
 *   // 什麼是「symbol」？
 *   const symbolCode = '2330' // 這是 symbol
 *   // 別名：
 *   const symbol = '2330' // 這是 symbol
 *   const symbolNumber = '2330' // 這是 symbol
 *   const symbolString = '2330' // 這是 symbol
 *
 *   //
 *   // 什麼是「name」？
 *   const name = '台積電' // 這是 name
 *   // 別名：
 *   const displayName = '台積電' // 這是 name
 *   const symbolName = '台積電' // 這是 name
 */
export const fr_instrument = proxy(new FrInstrument());
function covertToFromToObject(unixValues) {
    return {
        from: dayAPI(unixValues[0] * 1000),
        to: dayAPI(unixValues[1] * 1000),
    };
}
/* istanbul ignore next */
function TypingTesting() {
    expectType(fr_instrument.getFutures('2330'));
    expectType(fr_instrument.getFutures('2330'));
}
